import { route } from '@/ziggy-shim';
import AxiosClient from './AxiosClient';
import { api, apiConfig } from './LaravelClient';

export interface UserUpdateRequest {
  name: string;
  email: string;
  projects?: { project_id: number; role_id: number }[];
  companies?: { company_id: number; role_id: number }[];
  admin: boolean;
}

export interface UserCreateRequest {
  name: string;
  email: string;
}

export default {
  async create({
    name,
    email,
  }: UserCreateRequest) {
    return await AxiosClient.postRequest(route('api.v1.users.store'), {
      name,
      email,
    });
  },

  async update(id: Id, {
    name,
    email,
    projects,
    companies,
    admin,
  }: UserUpdateRequest) {
    return await AxiosClient.patchRequest(route('api.v1.users.update', id), {
      name,
      email,
      projects,
      companies,
      admin,
    });
  },

  async delete(id: Id) {
    return await AxiosClient.deleteRequest(route('api.v1.users.destroy', id));
  },

  async show(id: Id) {
    return await AxiosClient.getRequest(route('api.v1.users.show', id));
  },

  async index() {
    return await AxiosClient.getRequest(route('api.v1.users.index'));
  },

  async disable(id: Id, data: ApiRequests['api.v1.users.toggle-disable']) {
    return await api(route('api.v1.users.toggle-disable', id), 'post', {
      data,
    });
  },

  async adminIndex(page = 1) {
    return await apiConfig(route('api.v1.admin.users.index'), 'get', {
      params: {
        page,
      },
    });
  },

  admin: {
    async welcomeEmail(userId: Id) {
      return await apiConfig(route('api.v1.admin.users.welcome-email', userId), 'post');
    },
  },

  company: {
    async index(companyId: Id) {
      return await api(route('api.v1.companies.users.index', companyId), 'get');
    },

    async attachRole(companyId: Id, projectId: Id, data: ApiRequests['api.v1.companies.users.attach']) {
      return await api(route('api.v1.companies.users.attach', [companyId, projectId]), 'post', {
        data,
      });
    },

    async detachRole(companyId: Id, projectId: Id, data: ApiRequests['api.v1.companies.users.detach']) {
      return await api(route('api.v1.companies.users.detach', [companyId, projectId]), 'delete', {
        data,
      });
    },
  },
};
